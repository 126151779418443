import React, { memo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Bold } from "../structure/theme/styles.component"
import { ButtonLink } from "../structure/theme/button.component"
import { modules, useThemeContext } from "../structure/theme"
import Swiper from "../../components/swiper.component"
import { useTranslation } from "../translation/translate.component"

const Link = styled(ButtonLink).attrs({
  textColor: `white`,
  backgroundColor: modules(`navigation.footer.backgroundColor`),
  singleLine: true,
})`
  margin: 10px auto 4px;
  text-transform: none;
  max-width: 295px;
`

const InterventionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto 15px;
  max-width: 330px;
`

const Description = styled.div`
  flex: 1;
  margin-bottom: 7px;

  > p {
    margin: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

function InterventionExample ({ interventionExamples, reverseButton = false }) {
  const theme = useThemeContext()
  const { t } = useTranslation()

  if (interventionExamples.length === 0) {
    return null
  }

  return (
    <Swiper
      NoSwiperWrapper={Wrapper}
      bulletType="full"
      arrowType="far"
      foregroundColor={theme.modules.navigation.footer.backgroundColor}
    >
      {interventionExamples.map(({ title, description, link, linkText }) => (
        <InterventionWrapper key={link} data-testid="intervention_example">
          {title && <Bold as="div">{title}</Bold>}
          <Description
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <Link href={link} data-testid="intervention_example_link" ghost={!!reverseButton}>
            {linkText || t(`common:navigation.block_intervention_exemple_title`)}
          </Link>
        </InterventionWrapper>
      ))}
    </Swiper>
  )
}

InterventionExample.propTypes = {
  interventionExamples: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      linkText: PropTypes.string,
    }),
  ).isRequired,
  reverseButton: PropTypes.bool,
}

export default memo(InterventionExample)
